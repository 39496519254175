/* eslint-disable consistent-return */
import React from 'react';
import {
  Button, Card, Form, Input, message, Space,
} from 'antd';
import { Redirect } from 'react-router-dom';
import firebase from '../../firebase';
import UserForm from './user-form';
import { AuthContext } from '../../components/AuthContextProvider';

const db = firebase.firestore();

const ManageUsersPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [searchedUser, setSearchedUser] = React.useState();

  const { currentUser } = React.useContext(AuthContext);
  const [events, setEvents] = React.useState([]);

  const fetchEvents = () => {
    setLoading(true);
    setEvents([]);
    db.collection('events')
      .orderBy('sector', 'desc')
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          setEvents(snapshots.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        }
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchEvents();
  }, []);
  if (!currentUser?.admin) {
    return <Redirect to="/" />;
  }

  const search = async (values) => {
    setAdd(false);
    setLoading(true);
    const snapshot = await db.collection('users').doc(values.itsno).get();
    if (snapshot.exists) {
      setEdit(true);
      setSearchedUser(snapshot.data());
    } else {
      message.error('Not found');
    }
    setLoading(false);
  };

  const save = async (values) => {
    setSaving(true);
    const { itsno, admin } = values;
    const adminValue = !!admin;
    await db
      .collection('users')
      .doc(itsno.toString())
      .set({ ...values, admin: adminValue }, { merge: true });
    message.success('User saved');
    setSaving(false);
  };

  return (
    <div>
      <Card
        title="Manage users"
        extra={(
          <Button
            type="primary"
            onClick={() => {
              setAdd(true);
              setEdit(false);
            }}
          >
            Add
          </Button>
        )}
      >
        <Space align="center">
          <Form name="search" onFinish={search}>
            <Form.Item
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 20 }}
              name="itsno"
              label="ITS Number"
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 20,
                sm: { offset: 0 },
                md: { offset: 12 },
              }}
            >
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Card>

      {(edit || loading) && (
        <UserForm
          edit={edit}
          loading={loading}
          saving={saving}
          initialValues={searchedUser}
          onSave={save}
          events={events}
        />
      )}

      {add && <UserForm edit={edit} saving={saving} onSave={save} events={events} />}
    </div>
  );
};

ManageUsersPage.propTypes = {};

export default ManageUsersPage;
