/* eslint-disable no-underscore-dangle */
import React from 'react';
import Helpline from './helpline';

const Instructions = () => (
  <p>
    <ol>
      <li>Enter Registered ITS ID and click Verify</li>
      {/* <li>An OTP will be sent to the HOF`s registered phone number</li> */}
      {/* <li>Enter the OTP and click Verify OTP</li> */}
      <li>After successful verification you will be redirected to the waaz live stream.</li>
      <li>Click on the video to start the Live Stream.</li>
      <li>A button is provided to switch to fullscreen mode.</li>
    </ol>
    <br />
    <b>*Use Google Chrome for best experience.</b>
    <br />
    <br />
    <u><a href="https://youtu.be/mFzeT_wde8M" target="_blank" rel="noreferrer">Click here</a></u>
    {' '}
    to view instructional video.
    <br />
    <br />
    <Helpline />
  </p>
);

Instructions.propTypes = {};

export default Instructions;
