import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import EventPage from './pages/EventPage/index';
import './App.css';
import Login from './pages/LoginPage';
import AppLayout from './components/app-layout';
import Protected from './components/protected';
import AdminPage from './pages/AdminPage';
import { AuthContextProvider } from './components/AuthContextProvider';

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <AppLayout>
          <Switch>
            <Route exact path="/" component={Login} />
            <Protected>
              <Route exact path="/event" component={EventPage} />
              <Route exact path="/admin" component={AdminPage} />
            </Protected>
          </Switch>
        </AppLayout>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
