import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Switch, Card, Form, Input,
} from 'antd';

const EventForm = ({ initialValues, onSave }) => (
  <Card
    title={initialValues.id}
    bodyStyle={{ padding: 16, backgroundColor: initialValues.live ? '#4BB543' : '#ff9494' }}
  >
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onSave}
    >
      <Form.Item name="id" label="Sector">
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
        required
        rules={[
          {
            required: true,
            message: 'Please input name!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="videoURL"
        label="Video URL"
        required
        rules={[
          {
            required: true,
            message: 'Please input video URL!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="sector"
        label="Sector"
        required
        rules={[
          {
            required: true,
            message: 'Please select sector!',
          },
        ]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item name="link" label="PDF Link">
        <Input />
      </Form.Item>
      <Form.Item name="time" label="time">
        <Input />
      </Form.Item>
      <Form.Item name="text" label="text">
        <Input />
      </Form.Item>
      <Form.Item name="live" label="Live" valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>

      <Form.Item name="fallback" label="Fallback" valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          span: 14,
          sm: { offset: 0 },
          md: { offset: 6 },
        }}
      >
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  </Card>
);

EventForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EventForm;
