/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { message } from 'antd';
import firebase from '../../firebase';

const auth = firebase.auth();
const db = firebase.firestore();

const ICCByPass = (setCurrentUser, location) => {
  if (location.search) {
    try {
      const params = new URLSearchParams(location.search);
      if (location.pathname === '/event' && params.get('id')) {
        const enc = params.get('id');
        const dec = Buffer.from(enc, 'base64').toString();
        const _params = new URLSearchParams(dec);
        setCurrentUser(
          _params.has('sector')
            ? {
              name: _params.get('name'),
              itsno: _params.get('itsno'),
              mobile: _params.get('mobile'),
              sector: _params.get('sector'),
              active: _params.get('active'),
            }
            : null,
        );
      }
    } catch (err) {
      setCurrentUser(null);
    }
    return true;
  }
  return false;
};

export const AuthStateChangedOTP = (setCurrentUser, location) => auth.onAuthStateChanged(async (user) => {
  const iccByPass = ICCByPass(setCurrentUser, location);
  if (user && !iccByPass) {
    const snapshot = await db
      .collection('users')
      .where('mobile', '==', user.phoneNumber)
      .limit(1)
      .get();
    if (snapshot.empty) {
      setCurrentUser(null);
    } else {
      const fetched = snapshot.docs[0].data();
      setCurrentUser({ ...user, ...fetched });
    }
  } else {
    setCurrentUser(null);
  }
});

export const AuthStateChangedITS = (setCurrentUser) => auth.onAuthStateChanged(async () => {
  const userCurrent = localStorage.getItem('currentUser');
  const _params = userCurrent ? JSON.parse(userCurrent) : {};
  if (_params.itsno && _params.active) {
    setCurrentUser(_params);
  } else {
    message.error('Unauthorized');
    setCurrentUser(null);
  }
});
