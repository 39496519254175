import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCI6hVC9heCHfrbKkysTgqUe0a6fqtdMd8',
  authDomain: 'genform.firebaseapp.com',
  databaseURL: 'https://genform.firebaseio.com',
  projectId: 'genform',
  storageBucket: 'genform.appspot.com',
  messagingSenderId: '170130463837',
  appId: '1:170130463837:web:11c20dae3afeb0ee9741b4',
  measurementId: 'G-8KTFMFBL56',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
