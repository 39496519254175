import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.less';
import './App.css';
import App from './App';
// import firebase from './firebase';

// const config = fetch('/__/firebase/init.json')
//   .then((response) => response.json())
//   .then((response) => firebase.initializeApp(response));

// config.then(() => {
ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
// });
