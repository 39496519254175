/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Switch, Card, Form, Input, Select, Spin,
} from 'antd';

const UserForm = ({
  loading, saving, initialValues, onSave, edit, events,
}) => (
  <Card loading={loading} title={`${edit ? 'Edit' : 'Add'} details`} style={{ marginTop: 24 }}>
    <Spin spinning={saving}>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={initialValues}
        onFinish={onSave}
      >
        <Form.Item
          name="itsno"
          label="HOF ITS Number"
          required
          rules={[
            {
              required: true,
              message: 'Please input HOF ITS number!',
            },
          ]}
        >
          <Input disabled={edit} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          required
          rules={[
            {
              required: true,
              message: 'Please input name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mobile"
          label="Phone Number"
          required
          rules={[
            {
              required: true,
              message: 'Please input phone number!',
            },
          ]}
          extra="Make sure to start phone number with +91"
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="sector"
          label="Sector"
          required
          rules={[
            {
              required: true,
              message: 'Please select sector!',
            },
          ]}
        >
          <Select>
            {events.map((event) => (
              <Select.Option value={event.sector}>{event.sector}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="admin" label="Admin" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="active" label="Active" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 14,
            sm: { offset: 0 },
            md: { offset: 6 },
          }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  </Card>
);

UserForm.defaultProps = {
  loading: false,
  saving: false,
  initialValues: {
    admin: false,
    active: true,
  },
};

UserForm.propTypes = {
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  edit: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UserForm;
