import React, { useState } from 'react';
import CSVReader from 'react-csv-reader';
import { Card, Spin } from 'antd';
import firebase from '../firebase';

const CsvUploader = () => {
  const [isLoading, setLoading] = useState(false);
  const db = firebase.firestore();
  const { log } = console;

  const handleCSV = (data) => {
    setLoading(true);
    data.map((d) => {
      db.collection('users')
        .doc(d.itsno.toString())
        .set(d)
        .then((r) => {
          log(r);
        })
        .catch((e) => log(e));
      return null;
    });
    setLoading(false);
    log(data);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };

  return (

    <Spin spinning={isLoading}>
      <Card title="CSV Upload">
        <CSVReader
          cssClass="react-csv-input"
          label="Select CSV "
          onFileLoaded={handleCSV}
          parserOptions={papaparseOptions}
        />
      </Card>
    </Spin>
  );
};

export default CsvUploader;
