import React from 'react';
import { Card } from 'antd';
import ManageUsersPage from '../ManageUsersPage';
import ManageEventsPage from '../ManageEventsPage';
import CsvUploader from '../../components/csvUploader';
import { AuthContext } from '../../components/AuthContextProvider';

const AdminPage = () => {
  const [activeTab, setActiveTab] = React.useState('users');
  const { currentUser } = React.useContext(AuthContext);

  const tabList = React.useMemo(() => (currentUser.events
    ? [{ key: 'users', tab: 'Users' },
      { key: 'events', tab: 'Events' },
      { key: 'csvUpload', tab: 'CSV upload' }]
    : [{ key: 'users', tab: 'Users' }]), []);

  const contentList = React.useMemo(() => (currentUser.events
    ? { users: <ManageUsersPage />, events: <ManageEventsPage />, csvUpload: <CsvUploader /> }
    : { users: <ManageUsersPage /> }), []);

  return (
    <Card
      style={{ margin: '0 24px' }}
      tabList={tabList}
      activeTabKey={activeTab}
      onTabChange={(key) => {
        setActiveTab(key);
      }}
    >
      {contentList[activeTab]}
    </Card>
  );
};

AdminPage.propTypes = {

};

export default AdminPage;
