import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, message, Spin,
} from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

const VerifyOtp = ({ onCancel }) => {
  const [loading, setLoading] = React.useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await window.confirmationResult.confirm(values.otp);
    //   setTimeout(() => {
    //     history.push('/event');
    //   }, 3000);
    } catch (error) {
      message.error('Incorrect OTP');
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <Form size="large" name="login" onFinish={onFinish}>
        <Form.Item
          name="otp"
          rules={[{
            required: true,
            len: 6,
            pattern: new RegExp(/^[0-9]+$/),
            message: 'Please enter a valid 6-digit OTP',
          }]}
        >
          <Input
            maxLength={6}
            prefix={<PhoneOutlined />}
            placeholder="Enter 6-digit OTP"
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
          >
            VERIFY OTP
          </Button>
        </Form.Item>

        <Form.Item>
          <Button style={{ width: '100%' }} onClick={onCancel}>
            CANCEL
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

VerifyOtp.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default VerifyOtp;
