import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from './AuthContextProvider';

const Protected = (props) => {
  const { currentUser } = React.useContext(AuthContext);

  if (!currentUser) {
    return <Redirect to="/" />;
  }

  return props.children;
};

export default Protected;
