/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import {
  // Button,
  Card,
  Col,
  Descriptions,
  List,
  Row,
  Space,
  Typography,
  Button,
} from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Player from './player';
import firebase from '../../firebase';
import { AuthContext } from '../../components/AuthContextProvider';
import Helpline from '../LoginPage/helpline';

const EventPage = () => {
  const { currentUser, setCurrentUser } = React.useContext(AuthContext);
  const [event, setEvent] = React.useState(false);
  const history = useHistory();
  const location = useLocation();

  const db = firebase.firestore();
  React.useEffect(() => {
    if (currentUser?.sector) {
      let count = 0;
      const unsubscribe = db
        .collection('events')
        .doc(currentUser.sector)
        .onSnapshot((snapshot) => {
          setEvent(snapshot.data());
          if (count > 0) {
            history.push('/');
            history.replace(location);
          }
          count += 1;
        });
      return () => {
        unsubscribe();
      };
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (currentUser.itsno) {
      const unsubscribe = db
        .collection('users')
        .doc(`${currentUser.itsno}`)
        .onSnapshot((snapshot) => {
          const user = snapshot.data();
          if (!user || !user.active) {
            setCurrentUser(null);
          }
        });
      return () => {
        unsubscribe();
      };
    }
    return () => {};
  }, [currentUser]);

  return (
    <>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col md={18} xs={22}>
          <Card
            title={<Typography.Title level={3}>{event && event.name}</Typography.Title>}
            loading={event === false}
            style={{ width: '100%' }}
            bodyStyle={{ padding: 0 }}
            extra={(
              <Button href={event?.link} type="primary" target="_blank">
                Download PDF
              </Button>
            )}
            actions={[
              <List
                header={(
                  <Typography.Title level={3} underline style={{ textAlign: 'center' }}>
                    Instructions
                  </Typography.Title>
                )}
                style={{ textAlign: 'left' }}
                dataSource={[
                  'Click on the "Play" button or click on the video above to start playback of the live event.',
                  'Click on the "Switch to fullscreen" button to switch to fullscreen',
                  <Descriptions column={1}>
                    <Descriptions.Item label="Majlis Start" labelStyle={{ fontWeight: 'bold' }}>
                      {event?.time}
                    </Descriptions.Item>
                  </Descriptions>,
                ]}
                footer={(
                  <div style={{ margin: '0px 16px' }}>
                    <Helpline />
                  </div>
                )}
                split={false}
                size="small"
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />,
            ]}
          >
            {event === undefined || !event.live ? (
              <Row justify="center" align="middle" style={{ height: 300 }}>
                <Space direction="vertical" size="large" align="center">
                  <Typography.Title>{event?.text}</Typography.Title>
                  <Typography.Text>
                    Please refer to the instructions below for timings.
                  </Typography.Text>
                </Space>
              </Row>
            ) : (
              <Player videoURL={event?.videoURL} fallback={event?.fallback || false} />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EventPage;
