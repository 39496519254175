/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, message, Spin,
} from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import firebase from '../../firebase';
import { AuthContext } from '../../components/AuthContextProvider';

const db = firebase.firestore();

const VerifyIts = ({ onVerified }) => {
  const [loading, setLoading] = React.useState(false);
  const [itsVerified, setItsVerified] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState();

  const { signInWithPhoneNumber, recaptcha, authOTP } = React.useContext(AuthContext);

  const sendOtp = async (_phoneNumber) => {
    const confirmationResult = await signInWithPhoneNumber(_phoneNumber);
    message.success(`OTP sent to +91XXXXXX${_phoneNumber.substring(_phoneNumber.length - 4)}`, 10);
    window.confirmationResult = confirmationResult;
    onVerified(phoneNumber);
  };

  React.useEffect(() => {
    if (itsVerified) {
      setLoading(true);
      recaptcha({
        success: () => {},
        failure: () => {},
      }).then(async () => {
        await sendOtp(phoneNumber);
        setLoading(false);
      }).catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
    }
  }, [itsVerified]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const snapshot = await db.collection('users').doc(values.itsNo).get();
      if (snapshot.exists) {
        if (!authOTP) {
          localStorage.setItem('currentUser', JSON.stringify(snapshot.data()));
          window.location.href = '/event';
        } else {
          const _phoneNumber = snapshot.data().mobile;
          setPhoneNumber(_phoneNumber);
          setLoading(false);
          setItsVerified(true);
        }
      } else {
        setLoading(false);
        message.error('ITS number not found');
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <Form
        size="large"
        name="login"
        onFinish={onFinish}
      >
        <Form.Item
          name="itsNo"
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[0-9]+$/),
              message: 'Please enter a valid ITS number',
            },
          ]}
        >
          <Input maxLength={8} prefix={<IdcardOutlined />} placeholder="Enter ITS Number" disabled={itsVerified} />
        </Form.Item>

        <div id="recaptcha-container" style={{ marginBottom: 16 }} />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            disabled={itsVerified}
          >
            VERIFY ITS
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

VerifyIts.propTypes = {
  onVerified: PropTypes.func.isRequired,
};

export default VerifyIts;
