/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button, Card, Layout, Menu, PageHeader, Space, Typography,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Background from '../images/header-bg-jaali.png';
import { AuthContext } from './AuthContextProvider';

const AppLayout = ({ children }) => {
  const { currentUser, signOut } = React.useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  return (
    <Layout>
      <Layout.Header style={{ padding: 0, height: 82 }}>
        <PageHeader
          style={{
            backgroundImage: `url(${Background}), linear-gradient(to bottom, #871719 0%,#6c0d16  83%)`,
            padding: 0,
            height: '100%',
          }}
          ghost={false}
          extra={currentUser && (
            <Card size="small" bodyStyle={{ padding: '4px 4px 4px 24px' }} style={{ borderRadius: 50, margin: 16 }}>
              <Space align="center" direction="horizontal" size="large">
                <Typography.Text style={{ maxWidth: 160 }} ellipsis>{`${currentUser.name} (${currentUser.sector})`}</Typography.Text>
                <Button type="primary" style={{ borderRadius: 50 }} onClick={signOut}>Logout</Button>
              </Space>
            </Card>
          )}
          footer={currentUser?.admin && (
            <Menu
              mode="horizontal"
              style={{ padding: 16 }}
              selectedKeys={[`${location.pathname}`]}
              onClick={(info) => {
                history.replace(info.key);
              }}
            >
              <Menu.Item key="/admin">Admin</Menu.Item>
              <Menu.Item key="/event">Event</Menu.Item>
            </Menu>
          )}
        />
      </Layout.Header>
      <Layout.Content
        style={{
          marginTop: 128,
          marginBottom: 64,
          minHeight: 405,
        }}
      >
        {children}
      </Layout.Content>
      <Layout.Footer style={{ padding: 0 }}>
        <div className="info">

          {/* <p className="copy">
            <b>PLEASE NOTE</b>
            <br />
          </p> */}

          <div className="bottom-border" />
          <p className="copy pb-0">
            ©
            {new Date().getFullYear()}
            {' '}
            Anjuman-e-Fakhri Poona - All rights reserved.
          </p>
        </div>
      </Layout.Footer>
    </Layout>
  );
};

export default AppLayout;
