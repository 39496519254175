/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Card, Col, Row, Typography,
} from 'antd';
import { AuthContext } from '../../components/AuthContextProvider';
import VerifyIts from './verify-its';
import VerifyOtp from './verify-otp';
import Instructions from './instructions';

function Login() {
  const [phoneNumber, setPhoneNumber] = useState();

  const { currentUser } = React.useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/event" />;
  }

  return (
    <Row justify="center" align="middle" style={{ height: '100%' }} gutter={[16, 24]}>
      <Col>
        <Card
          title={(
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
              LOGIN FOR RELAY
            </Typography.Title>
          )}
          style={{ width: 352 }}
          bodyStyle={{ paddingTop: 64 }}
        >
          {!phoneNumber ? (
            <VerifyIts onVerified={setPhoneNumber} />
          ) : (
            <VerifyOtp
              phoneNumber={phoneNumber}
              onCancel={() => {
                setPhoneNumber(undefined);
              }}
            />
          )}
        </Card>
      </Col>
      <Col>
        <Card
          title={(
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
              INSTRUCTIONS
            </Typography.Title>
          )}
          style={{ width: 352 }}
        >
          <Instructions />
        </Card>
      </Col>
    </Row>
  );
}

export default Login;
