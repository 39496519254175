/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Splash from '../Splash';
import firebase from '../../firebase';
import { AuthStateChangedITS, AuthStateChangedOTP } from './authStateChanged';

export const AuthContext = React.createContext({
  currentUser: null,
  setCurrentUser: null,
  signInWithPhoneNumber: null,
  signOut: null,
  reloadCurrentUser: null,
  recaptcha: null,
});

const auth = firebase.auth();
const db = firebase.firestore();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(false);
  const [authOTP, setAuthOTP] = React.useState(true);
  const location = useLocation();
  React.useEffect(async () => {
    const snapshot = await db.collection('auth').doc('authType').get();
    const { otp } = snapshot.data();
    setAuthOTP(otp);
    const onAuthStateChange = () => (otp
      ? AuthStateChangedOTP(setCurrentUser, location)
      : AuthStateChangedITS(setCurrentUser, location));
    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, []);

  if (currentUser === false) {
    return <Splash />;
  }

  const recaptcha = ({ success, failure }) => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: success,
      'expired-callback': failure,
      defaultCountry: 'IN',
    });
    return window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const signInWithPhoneNumber = (phoneNumber) => {
    const appVerifier = window.recaptchaVerifier;
    return auth.signInWithPhoneNumber(phoneNumber, appVerifier);
  };

  const signOut = async () => {
    await auth.signOut();
    setCurrentUser(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        signInWithPhoneNumber,
        signOut,
        recaptcha,
        authOTP,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};
