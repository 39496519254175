import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space, Spin } from 'antd';
import { FullscreenOutlined, PlaySquareOutlined } from '@ant-design/icons';

const Player = (props) => {
  const { videoURL, fallback } = props;
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    if (!fallback) {
      window.jQuery('#player').YTPlayer();
      window.jQuery('#player').on('YTPReady', () => { setReady(true); });
    }
  }, [fallback]);

  if (fallback) {
    return (
      <iframe
        width="1127"
        height="634"
        src={`https://www.youtube.com/embed/${videoURL}`}
        title="Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }

  return (
    <Spin spinning={!ready}>
      <div
        className="player"
        id="player"
        style={{ width: '100%', height: '100%', background: 'black' }}
        data-property={`{
          videoURL:'${videoURL}',
          containment:'#player',
          autoPlay:false,
          mute:false,
          startAt:0,
          opacity:1,
          vol: 100,
          useOnMobile: true,
          ratio: "4/3",
          realfullscreen: true,
          stopMovieOnBlur: false,
          abundance:0,
          optimizeDisplay: true,
        }`}
      />
      <Space style={{ margin: 8 }}>
        <Button icon={<PlaySquareOutlined />} type="primary" onClick={() => { window.jQuery('#player').YTPPlay(); }}>Play</Button>
        <Button icon={<FullscreenOutlined />} type="primary" onClick={() => { window.jQuery('#player').YTPFullscreen(); }}>Switch to fullscreen</Button>
      </Space>
    </Spin>
  );
};

Player.propTypes = {
  videoURL: PropTypes.string.isRequired,
  fallback: PropTypes.bool.isRequired,
};

export default Player;
