import React from 'react';
import { Row, Space, Spin } from 'antd';

const Splash = () => (
  <Row justify="center" align="middle" style={{ height: '100vh' }}>
    <Space direction="vertical" size="large">
      <Spin size="large" />
    </Space>
  </Row>
);
export default Splash;
