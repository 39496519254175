import React from 'react';
import {
  Button, Card, List, message, Popconfirm, Space, Spin,
} from 'antd';
import { Redirect } from 'react-router-dom';
import { CloudUploadOutlined, DisconnectOutlined, SwapOutlined } from '@ant-design/icons';
import firebase from '../../firebase';
import EventForm from './event-form';
import { AuthContext } from '../../components/AuthContextProvider';

const db = firebase.firestore();

const ManageEventsPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [events, setEvents] = React.useState(false);

  const { currentUser } = React.useContext(AuthContext);

  const fetchEvents = () => {
    setLoading(true);
    setEvents([]);
    db.collection('events')
      .orderBy('sector', 'desc')
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          setEvents(snapshots.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        }
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchEvents();
  }, []);

  if (!currentUser?.admin) {
    return <Redirect to="/" />;
  }

  const save = async (values) => {
    setSaving(true);
    const { id, ...event } = values;
    // event.live = event.live || false;
    // event.fallback = event.fallback || false;
    await db.collection('events').doc(id).set(event, { merge: true });
    message.success('Event saved');
    fetchEvents();
    setSaving(false);
  };

  const updateLive = async (live) => {
    setSaving(true);
    try {
      const updates = events.map((event) => db.collection('events').doc(event.id).set({ live }, { merge: true }));
      await Promise.all(updates);
    } catch (err) {
      message.error(err.message);
    }
    setSaving(false);
    fetchEvents();
  };

  const switchToFakhri = async () => {
    setSaving(true);
    try {
      const fakhri = await db.collection('events').doc('Fakhri').get();
      const { videoURL } = fakhri.data();
      const updates = events.map((event) => db.collection('events').doc(event.id).set({ videoURL }, { merge: true }));
      await Promise.all(updates);
    } catch (err) {
      message.error(err.message);
    }
    setSaving(false);
    fetchEvents();
  };

  return (
    <Card extra={(
      <Space wrap>
        <Popconfirm
          title="Take all events offline?"
          onConfirm={() => { updateLive(false); }}
          okText="Take offline"
          cancelText="Cancel"
          okType="danger"
        >
          <Button danger icon={<DisconnectOutlined />}>All offline</Button>
        </Popconfirm>
        <Popconfirm
          title="Bring all events online?"
          onConfirm={() => { updateLive(true); }}
          okText="Bring online"
          cancelText="Cancel"
        >
          <Button type="primary" icon={<CloudUploadOutlined />}>All online</Button>
        </Popconfirm>
        <Popconfirm
          title="Switch all event URLs to Fakhri?"
          onConfirm={switchToFakhri}
          okText="Switch"
          cancelText="Cancel"
          okType="danger"
        >
          <Button danger icon={<SwapOutlined />}>Switch all to Fakhri</Button>
        </Popconfirm>
      </Space>
      )}
    >
      <Spin spinning={saving}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 3,
            xxl: 4,
          }}
          loading={loading}
          dataSource={events || []}
          renderItem={(event) => (
            <List.Item title={event.title}>
              <EventForm initialValues={event} onSave={save} />
            </List.Item>
          )}
        />
      </Spin>
    </Card>
  );
};

ManageEventsPage.propTypes = {

};

export default ManageEventsPage;
